.App {
  text-align: center;
  font-size: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  
  min-height: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.25em;
  color: black;
  border-bottom: 1px solid grey;
  padding: 5px;
  background:#696969;
 }
  


.App-header-center {
  background-color: #ffffff;
  min-height: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;
  color: black;
  border-bottom: 2px solid black;
}

 .main-gradient{
  background:#fff;
 }

.title-center {
  
    min-height: 1em;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    font-size: 1.25em;
    color:#fff;
    font-style: italic;
    font-weight: 600;
  }
  .title-left {
    background-color:  whitesmoke;
    min-height: 1em;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: Left;
    justify-content: center;
    font-size: 1.25em;
    color: black;
  }
.App-link {
  color: #61dafb;
}

.nav-menu {
  border-right: 1px solid grey;
  display: block;
  height: 100%;
  width:25%;
  min-width: 150px;
  max-width: 250px;
  background-color: rgb(240, 240, 240);
}

.p-0 {
  padding: 0;
}
.p-5 {
  padding: 9px;
}
 .px-5 {
  padding: 0 9px 0 9px;
 }

 .m-0 {
   margin: 0;
 }

 .toolbar-action {
    color: black;
 }

 .border-right {
   border-right: rgba(0, 0, 0, 0.40) solid 1px;
 }
 .MuiInput-underline:before{
  border-bottom: 1px solid #d3d3d3!important;
 }
 .MuiInput-underline:after{
    border-bottom: 2px solid #ffa500!important;
 }
 #assessor_recommendations{
   border: 1px solid #d3d3d3;
   padding: 12px;
   position: relative;
 }
 #assessor_recommendations:focus-visible{ 
  border: 2px solid #ffa500!important;
 }

 #divMenu{
   background: #D4D4D4;
 }
 #divMenu .MuiListItem-button.active,
 #divMenu .MuiListItem-button:focus,
 #divMenu .MuiListItem-button:hover{
   background: #ffa500;
 }
 textarea:focus-visible {
    outline:#ffa500;
    outline-width: 1;
 }


 .MuiPopover-paper {
  box-shadow: 2px 2px 7px rgb(0 0 0 / 8%)!important;
}